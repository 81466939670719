import type { AfCore } from "@olenbetong/appframe-core";

if (window.localStorage[af.article.id]) {
  const settings = JSON.parse(window.localStorage[af.article.id]);

  for (let user of Object.keys(settings)) {
    const userSettings = settings[user];
    const newKey = `${af.article.id}.${user}`;
    window.localStorage.setItem(newKey, JSON.stringify(userSettings));
  }

  window.localStorage.removeItem(af.article.id);
}

if (window.localStorage["statusCache"]) {
  window.localStorage.removeItem("statusCache");
}

export function getSettings(setting?: string, article?: string) {
  if (!af || !af.userSession) return null;

  const articleKey = article || af.article.id;
  const key = `${articleKey}.${af.userSession.login}`;
  const settings = JSON.parse(window.localStorage.getItem(key) || "{}");

  if (setting) {
    return settings[setting] || null;
  }

  return settings;
}

export function saveSettings(setting: string, value: any, article?: string) {
  if (!af || !af.userSession) return null;

  const articleKey = article || af.article.id;
  const key = `${articleKey}.${af.userSession.login}`;
  let userSettings = JSON.parse(window.localStorage.getItem(key) || "{}");

  if (typeof value !== "undefined") {
    userSettings[setting] = value;
  } else {
    userSettings = setting;
  }

  window.localStorage.setItem(key, JSON.stringify(userSettings));
}

af?.common?.expose("ob", [getSettings, saveSettings]);
