import type { AfCore } from "@olenbetong/appframe-core";
import { ArticleStatus, ArticleStatusItem } from ".";

export const articleId = "invoice-approval";
export async function getStatus(): Promise<ArticleStatus> {
  try {
    let result = await fetch("/api/compello");
    let data = await result.json();

    const { Approval, IsApprover } = data;
    const formatter = new Intl.DateTimeFormat(af.userSession.uiculture, {
      day: "numeric",
      month: "short",
      year: "numeric",
      timeZone: "UTC",
    });

    if (IsApprover) {
      const items: ArticleStatusItem[] = [];
      const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
      const sixDaysFromNow = Date.now() + threeDaysInMilliseconds * 2;

      for (let key in Approval) {
        if (Object.prototype.hasOwnProperty.call(Approval, key)) {
          const invoice = Approval[key];
          const { Description, Domain, DueDate, VoucherRef } = invoice;
          const dueDate =
            DueDate == undefined ? new Date(sixDaysFromNow) : new Date(DueDate);
          const timeUntilDueDate = dueDate.getTime() - Date.now();

          items.push({
            description: DueDate && `Due ${formatter.format(dueDate)}`,
            href: `/invoice-approval-details?id=${VoucherRef}`,
            severity:
              timeUntilDueDate < threeDaysInMilliseconds ? "danger" : false,
            text: `${Description} (${Domain})`,
          });
        }
      }

      const result: ArticleStatus = {
        count: data.Approval.length,
        details:
          items.length === 0
            ? false
            : {
                title:
                  af.common.getLocalizedString(`Invoice for approval`) +
                  ` (${items.length})`,
                items: items,
              },
      };

      return Promise.resolve(result);
    }
  } catch (error) {
    console.error((error as any).toString());
  }

  return { count: 0, details: false };
}

af.common.expose("ob.status.articles.invoiceApproval", {
  articleId,
  getStatus,
});
