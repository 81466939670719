function initSidebar() {
  // Highlight active article or parent article
  let hrefToHighlight = "/" + window.location.pathname.split("/")[1];
  let elementToHighlight = document.querySelector(
    `.c-sidebar__item[href="${hrefToHighlight}"]`
  );

  if (elementToHighlight) {
    elementToHighlight.classList.add("c-sidebar__item--active");
  }

  const mql = window.matchMedia("(min-width: 55em)");

  function toggleSidebar() {
    document.body.classList.toggle(
      mql.matches ? "af-desktop-hide-sidebar" : "af-mobile-show-sidebar"
    );

    if (mql.matches) {
      document.cookie =
        "desktopHideSidebar=" +
        document.body.classList.contains("af-desktop-hide-sidebar");
    }
  }

  let toggle = document.querySelector(".c-sidebar__toggle");
  let overlay = document.querySelector(".c-sidebar__overlay");

  if (toggle) {
    toggle.addEventListener("click", toggleSidebar);
  }

  if (overlay) {
    overlay.addEventListener("click", toggleSidebar);
  }
}

document.addEventListener("DOMContentLoaded", initSidebar);

// @ts-expect-error
if ((!window.React || !window.ReactDOM) && window.preactCompat) {
  // @ts-expect-error
  window.React = window.preactCompat;
  // @ts-expect-error
  window.ReactDOM = window.preactCompat;
}
