// This path cannot be put directly into the import statement, as that will cause
// esbuild to try (and fail) to bundle it.
const CHANGE_DOMAIN_MODULE =
  "/lib/@olenbetong/ob-change-domain/1.0.0/index.min.js";

async function clearDocumentCache() {
  let cacheNames = await caches.keys();
  for (let cacheName of cacheNames) {
    if (cacheName.includes("document")) {
      await caches.delete(cacheName);
    }
  }
}

document.addEventListener("DOMContentLoaded", function () {
  async function openChangeDomainDialog(fallbackUrl: typeof window.location) {
    try {
      await import(CHANGE_DOMAIN_MODULE);
      if (customElements.get("ob-change-domain")) {
        let elem = document.querySelector<HTMLElement>("ob-change-domain");
        elem?.addEventListener(
          "domainchange",
          // @ts-ignore
          async ({ detail }) => {
            if (af.userSession.domain !== detail) {
              // Document cache includes sidebar, which has domain visible
              // Clearing the cache to ensure the new domain is shown
              try {
                await clearDocumentCache();
              } catch (error) {}

              window.location.reload();
            }
          },
          { once: true }
        );
        // @ts-ignore
        elem.open = true;
      } else {
        window.location = fallbackUrl;
      }

      let appRoot = document.querySelector(".af-app-with-sidebar");
      if (appRoot) {
        appRoot.classList.remove("af-mobile-show-sidebar");
      }
    } catch (error) {
      console.error(error);
      window.location = fallbackUrl;
    }
  }

  // Override any change domain link to use the change-domain dialog instead of
  // going to the change-domain article.
  document.querySelectorAll("[href*=change-domain]").forEach((link) => {
    link.addEventListener("click", function handleChangeDomainClick(event) {
      event.preventDefault();
      // @ts-expect-error
      openChangeDomainDialog(event.target.href);
    });
  });
});
