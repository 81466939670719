document.addEventListener("click", async function (evt) {
  let btn = (evt.target as HTMLElement)?.closest?.(
    "[data-action=clear-cache]"
  ) as HTMLElement | undefined;
  if (btn) {
    let cache = btn.dataset.cache;
    let method = btn.dataset.cacheMethod || "clear";
    try {
      let result = await fetch(`/api/debug/cache/${method}/${cache}`, {
        headers: {
          accept: "application/json, text/javascript, */*; q=0.01",
          "x-requested-with": "XMLHttpRequest",
        },
        method: "POST",
        mode: "cors",
        credentials: "include",
      });

      if (result.ok) {
        let data = await result.json();
        if (data === true) {
          alert(`Cache '${cache}' cleared successfully!'`);
        } else {
          alert(`Failed to clear cache '${cache}': ${data}`);
        }
      } else {
        alert(
          `Failed to clear cache '${cache}': ${result.status} ${result.statusText}`
        );
      }
    } catch (error) {
      alert((error as any).toString());
    }
  }
});
