import type {
  FieldDefinition,
  RetrieveParameters,
} from "@olenbetong/appframe-data";

export async function execute(
  procedureName: string,
  params: Record<string, any> = {}
) {
  let body = {
    operation: "execute",
    resourceName: procedureName,
    timeout: 30,
    ...params,
  };
  let options = {
    body: JSON.stringify(body),
    method: "POST",
    headers: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };

  let response = await fetch("/api/data", options);

  if (response.ok) {
    let data = await response.json();

    if (data.success) {
      return data.success;
    }

    throw Error(
      data.error ||
        `Unknown error when executing stored procedure '${procedureName}'`
    );
  } else {
    throw Error(
      `Failed to execute procedure '${procedureName}': ${response.status} ${response.statusText}`
    );
  }
}

export async function retrieve<T = any>(
  viewName: string,
  fields: (string | FieldDefinition)[] = [],
  parameters: RetrieveParameters<any> = {}
): Promise<T[]> {
  let requestInit = {
    method: "POST",
    body: JSON.stringify({
      operation: "retrieve",
      resourceName: viewName,
      excludeFieldNames: false,
      fields: fields.map((field) =>
        typeof field === "string" ? { name: field } : field
      ),
      ...parameters,
    }),
  };

  let response = await fetch("/api/data", requestInit);

  if (response.ok) {
    let data = await response.json();

    if (data.success) {
      return data.success;
    }

    throw Error(
      data.error || `Unknown error when fetching data from view '${viewName}'`
    );
  } else {
    throw Error(
      `Failed to fetch data from view '${viewName}': ${response.status} ${response.statusText}`
    );
  }
}
