import { ArticleStatus } from ".";
import type { AfCore } from "@olenbetong/appframe-core";
import * as DataApi from "../data-api";

export type CourseRecord = {
  PrimKey: string;
  CourseID: number;
  Title: string;
  Description: string;
  Duration: number | null;
  Mandatory: boolean;
  CurrentSlide: number | null;
  Completed: Date | null;
  Progress: unknown
}

export const articleId = "training";
export async function getStatus(): Promise<ArticleStatus> {
  try {
    let data = await DataApi.retrieve<CourseRecord>(
      "aviw_Personnel_MyCourses",
      [
        "PrimKey",
        "CourseID",
        "Title",
        "Description",
        "Duration",
        "Mandatory",
        "CurrentSlide",
        "Completed",
        "Progress",
      ],
      { whereClause: "[Mandatory] = 1 AND [Completed] IS NULL" }
    );

    let result: ArticleStatus = {
      count: data.length,
      details:
        data.length === 0
          ? false
          : {
              title:
                af.common.getLocalizedString(
                  "Mandatory courses not completed"
                ) + ` (${data.length})`,
              items: data.map((course) => ({
                description: course.Description,
                href: `/api/personnel/course/${course.CourseID}/signup`,
                text: `${course.Title} (ca. ${course.Duration} min)`,
              })),
            },
    };

    return result;
  } catch (error) {
    console.error((error as any).toString());
  }

  return { count: 0, details: false };
}

af.common.expose("ob.status.articles.training", {
  articleId,
  getStatus,
});
