let hasEnabled: any[] = [];

function enableUpdate(worker: ServiceWorker) {
  let updateButton = document.querySelector("[data-update-worker]");
  if (updateButton && !hasEnabled.includes(worker)) {
    hasEnabled.push(worker);
    updateButton.addEventListener("click", () => {
      worker.postMessage({ action: "skipWaiting" });

      let menu = updateButton?.closest("ul");
      // @ts-expect-error
      updateButton?.closest("ob-disclosure-nav")?.toggleMenu?.(menu, false);
    });
  }
  document.querySelector(".c-user-menu")?.classList.add("update-available");

  console.debug("Enable update for service worker with state: ", worker.state);
  worker.addEventListener("statechange", () => {
    console.debug("Pending service worker changed state to: ", worker.state);
    switch (worker.state) {
      case "activated":
        window.location.reload();
        break;
    }
  });
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((reg) => {
    if (reg.waiting) {
      enableUpdate(reg.waiting);
    }

    reg.addEventListener("updatefound", () => {
      let newWorker = reg.installing;
      newWorker?.addEventListener("statechange", () => {
        switch (newWorker?.state) {
          case "installed":
            enableUpdate(newWorker);
            break;
        }
      });
    });
  });
}
