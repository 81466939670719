import type { AfCore } from "@olenbetong/appframe-core";
import * as DataApi from "../data-api";
import { ArticleStatus, ArticleStatusItem } from ".";

const filters = {
  OpenIssues: {
    title: "Åpne saker",
    description: "Alle åpne saker du har tilgang til.",
    filter: "",
  },
  ToBeReviewedByMe: {
    title: "Skal gjennomgås av meg",
    description: "Åpne saker hvor det ikke har blitt opprettet aksjoner.",
    filter: "ToBeReviewed",
  },
  ToBeVerifiedByMe: {
    title: "Skal verifiseres av meg",
    description: "Lukkede saker hvor resultatet skal verifiseres av deg.",
    filter: "ToBeVerifiedByMe",
  },
  ToBeClosedByMe: {
    title: "Skal lukkes av meg",
    description:
      "Saker du er ansvarlig for, og hvor alle aksjoner er fullført.",
    filter: "ToBeClosedByMe",
  },
  OpenActionsAssignedToMe: {
    title: "Mine aksjoner",
    description: "Alle saker hvor du har åpne aksjoner.",
    filter: "AssignedToMe",
  },
  AwaitingResponseFromMe: {
    title: "Venter på svar fra meg",
    description:
      "Saker hvor noen har bedt om å få svar fra deg på en kommentar/spørsmål.",
    filter: "ResponseFromMe",
  },
  OpenIssuesReportedByMe: {
    title: "Saker rapportert av meg",
    description: "Åpne saker innraportert av deg.",
    filter: "ReportedByMe",
  },
};

export const articleId = "issuetracker";

export async function getStatus(): Promise<ArticleStatus> {
  try {
    let data = await DataApi.execute("sstp_Improve_GetIssuesCounts");

    if (data.Table && data.Table.length) {
      let myIssues = data.Table[0];
      const items: ArticleStatusItem[] = [];
      let issuesCount = 0;
      [
        "AwaitingResponseFromMe",
        "OpenActionsAssignedToMe",
        "ToBeClosedByMe",
        "ToBeReviewedByMe",
        "ToBeVerifiedByMe",
      ].forEach((type) => (issuesCount += myIssues[type]));

      for (let key in myIssues) {
        if (
          Object.keys(myIssues).includes(key) &&
          filters[key as keyof typeof filters] &&
          myIssues[key] > 0
        ) {
          const { description, filter, title } = filters[key as keyof typeof filters];
          items.push({
            description: description,
            href: "/issuetracker?" + filter,
            text: `${title} (${myIssues[key]})`,
          });
        }
      }

      const result: ArticleStatus = {
        count: issuesCount,
        details:
          items.length <= 1
            ? false
            : {
                // don't show details if only OpenIssues has a count
                items: items,
                title: `Saker til behandling (${issuesCount})`,
              },
      };

      return result;
    }
  } catch (error) {
    console.error((error as any).toString());
  }

  return { count: 0, details: false };
}

af.common.expose("ob.status.articles.issuetracker", {
  articleId,
  getStatus,
});
